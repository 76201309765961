import '../styles/index.scss';
import 'bootstrap';
import $ from 'jquery';
import { tns } from "tiny-slider/src/tiny-slider";
import Cookies from 'js-cookie';
import AOS from 'aos';

if (process.env.NODE_ENV === 'development') {
  require('../index.html');
}

$(function() {
  // splash page cookie detection
  let cookieName = 'bull-busters-splash-page-cookie';
  const splashModal = $('#splash-modal');

  if (!Cookies.get(cookieName)) {
    splashModal.modal('show');
    Cookies.set(cookieName, '1', {expires: 7});
    window.setTimeout(function() {
      splashModal.modal('hide');
    }, 5000);
  }

  // splash page custom animations
  splashModal.on('show.bs.modal', function () {
    addAnimation('animate__fadeIn');
  });

  splashModal.on('hide.bs.modal', function () {
    addAnimation('animate__fadeOut');
  });

  function addAnimation(animationClass) {
    $('#splash-modal .modal-dialog').attr('class', 'modal-dialog animate__animated ' + animationClass);
  }

  // Nav
  let verticalOffset = ($(window).width() <= 576 ) ? 55 : 0;

  // recalculate verticalOffset every time we resize
  $(window).resize(function() {
    verticalOffset = ($(window).width() <= 576 ) ? 55 : 0;
  });

  $('.nav-item').on('click', function(){
    let navItem = $(this);
    let anchorId = $(this).find('a').attr('href');

    navItem.addClass('is-active').siblings().removeClass('is-active');
    $([document.documentElement, document.body]).animate({
      scrollTop: $(anchorId).offset().top - verticalOffset
    }, 500);
  })

  // Dynamic video modals + autoplay/stop + analytics
  function initVideoModals() {
    let trigger = $('body').find('[data-target="#video-modal"]');
    trigger.on('click', function () {
      let theModal = $(this).data('target'),
          videoSRC = $(this).attr('data-video-url'),
          videoTitle = $(this).data('title'),
          videoSRCauto = videoSRC + '?autoplay=1&playsinline=1'; 
      $(theModal + ' iframe').attr('src', videoSRCauto);
      videoClick(videoTitle);
      $('#video-modal').on('hidden.bs.modal', function () {
        $("#video-modal iframe").attr("src", videoSRC);
      });
    });
  }

  // video carousel
  let videoSlider = tns({
    container: '.video-slider',
    items: 1,
    autoplay: false,
    autoplayButtonOutput: false,
    controlsContainer: "#video-slider-controls",
    nav: false,
    responsive: {
      1024: {
        items: 2
      },
      1200: {
        items: 3
      }
    }
  });

  // must wait until after the slider is initialized to add our click handler
  initVideoModals();

  // pause/play on video modal
  $('#video-modal').on('shown.bs.modal', function() {
    videoSlider.pause();
  });

  $('#video-modal').on('hidden.bs.modal', function () {
    initVideoModals();
  });

  // quiz slider
  let quizSlider = tns({
    container: '.quiz-slider',
    items: 1,
    autoplay: false,
    autoplayButtonOutput: false,
    nav: false,
    loop: false,
    controls: false,
    touch: false,
    mode: 'gallery'
  });
  // quiz output slider
  let quizOutputSlider = tns({
    container: '.output-slider',
    items: 1,
    autoplay: false,
    autoplayButtonOutput: false,
    nav: false,
    loop: false,
    controls: false,
    touch: false,
    mode: 'gallery',
    onInit: quizOutputInit()
  });

  let correctAnswers = 0;

  function quizOutputInit() {
    $('.answer-slide').hide();
    $('.slide-promo .slide-nav').hide();

    if (localStorage.getItem('formSubmitted') === true) {
      $('.form-submission').hide();
      $('#confirmation').fadeIn();
    }
  }

  // Handle answers
  $('#quiz .answer').on('click', function() {
    $('.promo').hide();
    $('.slide-promo .slide-nav').fadeIn();

    if ($(this).data('id') === 0) {
      $('.answer-slide.correct').fadeIn();
      $('.answer-slide.incorrect').hide();
      $(this).addClass('bg-success-primary');
      correctAnswers++
    }else {
      $('.answer-slide.correct').hide();
      $('.answer-slide.incorrect').fadeIn();
      $(this).addClass('bg-danger');
    }

    $('#correct-answers').text(correctAnswers);

    $(this).closest('.button-group').find('.answer').css('pointer-events', 'none');
  });

  // Visual controls and sliding of quiz slider
  $('#quiz .btn-next').on('click', function() {
    $('.answer-slide.correct, .answer-slide.incorrect').hide();
    setTimeout(function() {
      quizSlider.goTo('next');
      quizOutputSlider.goTo('next');
    }, 200)
  });

  // Reset quiz and try again
  $('#quiz .try-again').on('click', function() {
    $('.promo').show();
    $('.answer-slide.correct, .answer-slide.incorrect').hide();
    $('#quiz .answer').removeClass('bg-success-primary bg-danger').css('pointer-events', 'auto');
    correctAnswers = 0;

    setTimeout(function() {
      quizSlider.goTo('first');
      quizOutputSlider.goTo('first');
      $('#quiz .quiz-counter').html('<span class="numerator">1</span>/6');
    }, 200);
  });

  function setNumerator() {
    let quizInfo = quizSlider.getInfo();
    console.log('x ', quizInfo.index);

    if (quizInfo.index < 6) {
      $('#quiz .numerator').text(quizInfo.index + 1);
    } else {
      $('.quiz-counter').empty();
    }
  }

  //init
  setNumerator();

  quizSlider.events.on('indexChanged', setNumerator);


  // Form submission
  $('#submission-form').on('submit', function(e) {
    e.preventDefault();
    localStorage.setItem('formSubmitted', true);
    $('.form-submission').hide();
    $('#confirmation').fadeIn();
  })

  // animations
  AOS.init({
    once: true,
  });

  // analytics
  $('#fact-slider-controls button').on('click', function() {
    sliderNavClick((this).data('controls'));
  })

  $('#offset-panels ')

  function videoClick(videoTitle) {
    window.gtag('event', 'click', {
      'event_category' : 'Video Play',
      'event_label' : videoTitle
    });
  }

  function sliderNavClick(direction) {
    window.gtag('event', 'click', {
      'event_category' : 'Question Scroll',
      'event_label' : direction
    });
  }

  // function generalClick(category, label) {
  //   window.gtag('event', 'click', {
  //     'event_category' : category,
  //     'event_label' : label
  //   });
  // }
});

